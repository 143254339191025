import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Obfuscate from "react-obfuscate"

const IndexPage = () => (
  <Layout>
    <SEO title="Domains For Sale | Buy Oppo.co.uk and Oppo.uk" />
    <h1>For Sale</h1>
    <ul className="domainBlocks">
      <li>oppo.co.uk</li>
      <li>oppo.uk</li>
    </ul>
    <p style={{ padding: "0 16px" }}>
      Having been privately owned for over thirty years, the domains oppo.co.uk
      and oppo.uk are now being offered for sale for the first time.
    </p>

    <div
      style={{
        background: `#fff`,
        borderRadius: 8,
        padding: 24,
        border: `2px solid #007AFF`,
        boxShadow: "0 0 32px #007aff22",
      }}
    >
      <h2 style={{ color: `#007AFF`, marginBottom: 16 }}>
        Please direct offers to{" "}
        <Obfuscate
          email="sales@oppo.co.uk"
          headers={{
            subject: "Oppo Domain Purchase Enquiry",
          }}
        />
      </h2>
      <p style={{ margin: 0, fontSize: "16px" }}>
        Screened offers will be delivered to the owner’s lawyer for
        consideration.
      </p>
    </div>
  </Layout>
)

export default IndexPage
